// OscarHelpers.js

import { message as antdMessage } from 'antd';
import { useCallback, useRef, useState, useEffect } from 'react';
import { useAtom } from 'jotai';
import { mainColorAtom, secondaryColorAtom, messageHistoryAtom, accessTokenAtom } from '../Atoms';

/**
 * Custom hook that encapsulates conversation logic:
 * - loading/saving from localStorage
 * - creating new convos
 * - selecting / renaming / deleting convos
 */
export const useConversations = () => {
  const [conversations, setConversations] = useState([
    {
      id: 1,
      name: 'Conversation 1',
      messages: [],
    },
  ]);
  const [selectedConversationId, setSelectedConversationId] = useState(1);

  // Load from localStorage on mount
  useEffect(() => {
    const savedConversations = localStorage.getItem('oscar-conversations');
    if (savedConversations) {
      try {
        setConversations(JSON.parse(savedConversations));
      } catch (err) {
        console.error('Failed to parse stored conversations:', err);
      }
    }
  }, []);

  // Save to localStorage whenever conversations changes
  useEffect(() => {
    localStorage.setItem('oscar-conversations', JSON.stringify(conversations));
  }, [conversations]);

  // Helpers
  const handleCreateNewConversation = useCallback((firstName) => {
    const newId = conversations.length + 1;
    const date = new Date();
    const hour = date.getHours();
    const groet = hour < 12 ? 'Goedemorgen' : hour < 18 ? 'Goedemiddag' : 'Goedenavond';

    const newConvo = {
      id: newId,
      name: `Conversation ${newId}`,
      messages: [
        { role: 'system', content: `${groet} ${firstName}, dit is een nieuwe chat!` },
      ],
    };
    setConversations((prev) => [...prev, newConvo]);
    setSelectedConversationId(newId);
  }, [conversations]);

  const handleSelectConversation = useCallback((id) => {
    setSelectedConversationId(id);
  }, []);

  const handleRenameConversation = useCallback((id, newName) => {
    setConversations((prev) => 
      prev?.map((c) => (c.id === id ? { ...c, name: newName } : c))
    );
  }, []);

  const handleDeleteConversation = useCallback((id) => {
    setConversations((prev) => {
      let updated = prev.filter((c) => c.id !== id);
      if (updated.length === 0) {
        updated = [
          {
            id: 1,
            name: 'Conversation 1',
            messages: [],
          },
        ];
      }
      setSelectedConversationId(updated[0].id);
      return updated;
    });
  }, []);

  const getActiveConversationMessages = useCallback(() => {
    const current = conversations.find((c) => c.id === selectedConversationId);
    if (!current) return [];
    return current.messages;
  }, [conversations, selectedConversationId]);

  const setActiveConversationMessages = useCallback(
    (newMessages) => {
      setConversations((prev) => {
        const convoIndex = prev.findIndex((c) => c.id === selectedConversationId);
        if (convoIndex === -1) return prev;
        const updatedConvo = { ...prev[convoIndex], messages: newMessages };
        const updatedConvos = [...prev];
        updatedConvos[convoIndex] = updatedConvo;
        return updatedConvos;
      });
    },
    [selectedConversationId]
  );

  return {
    conversations,
    selectedConversationId,
    handleCreateNewConversation,
    handleSelectConversation,
    handleRenameConversation,
    handleDeleteConversation,
    getActiveConversationMessages,
    setActiveConversationMessages,
  };
};

/**this 
 * Helper function to copy message content to clipboard
 */
export const handleCopyMessage = (content) => {
  if (!content) return;
  navigator.clipboard
    .writeText(content)
    .then(() => antdMessage.success('Message copied!'))
    .catch(() => antdMessage.error('Failed to copy.'));
};
