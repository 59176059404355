// OscarChat2.js

import React, {
  useState,
  useEffect,
  useRef,
  useMemo,
  useCallback,
} from 'react';
import { useAtom } from 'jotai';
import {
  Layout,
  Input,
  Button,
  Modal,
  Card,
  Row,
  Col,
  Switch,
  Space,
  Tooltip,
  Popconfirm,
  message as antdMessage,
  Upload,
  Select,
  Skeleton,
  Menu,
  Typography,
  Checkbox,
} from 'antd';
import {
  CopyOutlined,
  DeleteOutlined,
  SettingOutlined,
  SendOutlined,
  RedoOutlined,
  EditOutlined,
  SaveOutlined,
  CloseCircleOutlined,
  UploadOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import ReactMarkdown from 'react-markdown';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import { mainColorAtom, secondaryColorAtom, accessTokenAtom,  } from '../Atoms';
import MarkdownRenderer from './MarkdownRenderer';

// ====== Import your new helpers ====== //
import { 
  useConversations,
  handleCopyMessage 
} from './OscarHelpers';
import {
  simulateStreamingText,
  callOpenAPI,
  handleFileUploadChange,
  callBingSearch,
} from './OscarApi';

// ====== If you want a context: ====== //
import { OscarContext } from './OscarContext';

// ====== MAIN OSCAR COMPONENT ====== //
const Oscar = ({ questions = [] }) => {
  const { Header, Sider, Content, Footer } = Layout;

  // questions = questions.length > 0 ? questions : [
  //   'Vind een bedrijf voor me dat websites bouwt.',
  //   'Welke bedrijven zijn populair in de IT sector?',
  //   'Wat zijn de beste restaurants in de buurt?',
  //   'Kun je me helpen met het vinden van een goede tandarts?',
  // ];

  // Jotai state
  const [mainColor] = useAtom(mainColorAtom);
  const [secondaryColor] = useAtom(secondaryColorAtom);
  const [accessToken] = useAtom(accessTokenAtom);

  // Local states
  const [inputMessage, setInputMessage] = useState('');
  const [isProcessing, setIsProcessing] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [customSystemMessage, setCustomSystemMessage] = useState('');
  const [useWebSearch, setUseWebSearch] = useState(false);
  const [selectedSearchTypes, setSelectedSearchTypes] = useState(['web']);
  const [uploadedFile, setUploadedFile] = useState(null);

  // For editing messages
  const [editIndex, setEditIndex] = useState(null);
  const [editContent, setEditContent] = useState('');

  // Conversation logic from custom hook
  const {
    conversations,
    selectedConversationId,
    handleCreateNewConversation,
    handleSelectConversation,
    handleRenameConversation,
    handleDeleteConversation,
    getActiveConversationMessages,
    setActiveConversationMessages,
  } = useConversations();

  // This holds the partial (streaming) text for the AI's last response
  const [streamedResponse, setStreamedResponse] = useState('');

  // Refs
  const chatHistoryRef = useRef(null);
  const inputRef = useRef(null);

  // Basic firstName logic (example)
  const fullName = '';
  const words = fullName.split(' ');
  const firstName = words.slice(0, words.length - 1).join(' ');

  // ====== EFFECTS ====== //

  // Scroll chat to bottom when conversation messages change
  useEffect(() => {
    if (chatHistoryRef.current) {
      chatHistoryRef.current.scrollTop = chatHistoryRef.current.scrollHeight;
    }
  }, [conversations, selectedConversationId]);

  // If the selected conversation has no messages, add a greeting
  useEffect(() => {
    const convoIndex = conversations.findIndex((c) => c.id === selectedConversationId);
    if (convoIndex === -1) return;

    const currentConvo = conversations[convoIndex];
    if (currentConvo.messages.length === 0) {
      const date = new Date();
      const hour = date.getHours();
      const groet =
        hour < 12 ? 'Goedemorgen' : hour < 18 ? 'Goedemiddag' : 'Goedenavond';
      const newConvo = { ...currentConvo };
      newConvo.messages = [
        { role: 'system', content: `${groet} ${firstName}, hoe kan ik je helpen?` },
      ];

      const updatedConvos = [...conversations];
      updatedConvos[convoIndex] = newConvo;
      // We don't call setConversations directly here, 
      // but we can do so or call setActiveConversationMessages
      // for clarity, we just do:
      updatedConvos[convoIndex] = newConvo;
      // This effectively sets the entire new conversation state
      // to ensure we keep the same structure
      // (or call `setActiveConversationMessages` if you prefer).
      // We'll do the entire setConversations here:
      // (Note: We could also do this in the custom hook if we like.)
      setTimeout(() => {
        // Slight delay to ensure no race conditions
        localStorage.setItem('oscar-conversations', JSON.stringify(updatedConvos));
      }, 0);
    }
  }, [conversations, selectedConversationId, firstName]);

  // ====== ACTION HANDLERS ====== //

  // Clear only the active conversation’s messages
  const handleClearChat = useCallback(() => {
    const date = new Date();
    const hour = date.getHours();
    const groet =
      hour < 12 ? 'Goedemorgen' : hour < 18 ? 'Goedemiddag' : 'Goedenavond';

    const newMsgArray = [
      { role: 'system', content: `${groet} ${firstName}, hoe kan ik je helpen?` },
    ];
    setActiveConversationMessages(newMsgArray);
  }, [setActiveConversationMessages, firstName]);

  const handleDialogOpen = useCallback(() => setIsDialogOpen(true), []);
  const handleDialogClose = useCallback(() => setIsDialogOpen(false), []);

  // EDIT MESSAGE
  const handleEditMessage = (index) => {
    const currentMsgs = getActiveConversationMessages();
    setEditIndex(index);
    setEditContent(currentMsgs[index].content);
  };

  const handleSaveEdit = () => {
    if (editIndex === null) return;
    const currentMsgs = getActiveConversationMessages();
    const updatedHistory = [...currentMsgs];
    updatedHistory[editIndex].content = editContent;
    setActiveConversationMessages(updatedHistory);
    setEditIndex(null);
    setEditContent('');
    antdMessage.success('Message updated successfully!');
  };

  const handleCancelEdit = () => {
    setEditIndex(null);
    setEditContent('');
  };

  const handleRetryMessage = async (index) => {
    const currentMsgs = getActiveConversationMessages();
    try {
      const userMsg = currentMsgs[index];
      if (!userMsg || userMsg.role !== 'user') {
        antdMessage.warning('Cannot retry this message.');
        return;
      }
      // Remove the AI reply that followed
      if (currentMsgs[index + 1] && currentMsgs[index + 1].role === 'system') {
        currentMsgs.splice(index + 1, 1);
      }
      setActiveConversationMessages(currentMsgs);
      await handleSendMessage(userMsg.content, index);
    } catch (error) {
      console.error('Retry error:', error);
      antdMessage.error('Retry failed. Check console for details.');
    }
  };

  // SEND MESSAGE
const handleSendMessage = useCallback(
  async (message, retryIndex = null) => {
    if (message.trim() === '') {
      return;
    }

    const currentMsgs = getActiveConversationMessages();
    let updatedMessageHistoryLocal = currentMsgs;

    // Als het geen retry is, voeg de user-message toe
    if (retryIndex === null) {
      updatedMessageHistoryLocal = [
        ...currentMsgs,
        { role: 'user', content: message },
      ];
      setActiveConversationMessages(updatedMessageHistoryLocal);
    }

    setIsProcessing(true);

    try {
      let bingResults = null;

      // ------------------ 1) Bing-search als user WebSearch heeft aangezet ------------------
      if (useWebSearch && selectedSearchTypes.length > 0) {
        bingResults = await callBingSearch(message, selectedSearchTypes);

        // In plaats van de rauwe JSON direct in de conversatie te zetten, doen we:
        // 2) Maak een prompt waarin je die Bing-data aan OpenAI geeft

        // We serializen de JSON-resultaten van Bing
        const bingResultsJson = JSON.stringify(bingResults, null, 2);

        // 3) Voeg instructies toe voor OpenAI om de Bing-resultaten samen te vatten 
        const summaryPrompt = `
          Hieronder zie je Bing-zoekresultaten in JSON. 
          Maak op basis hiervan een duidelijke, beknopte Nederlandse samenvatting 
          en vermeld ook de bijbehorende bronnen (URL's) erbij. Formatteer her goed.
          
          {bronnaam 1, bronnaam 2, etc.}
          

          ${bingResultsJson}

          Samenvatting (in het Nederlands):
        `;

        // 4) Doe nu een aparte OpenAI-call met deze prompt
        // Let op: je kunt ervoor kiezen om "updatedMessageHistoryLocal" mee te geven 
        // als je wilt dat de volledige conversatiegeschiedenis wordt meegenomen. 
        // Of je houdt het simpel en geeft alleen de summaryPrompt mee.
        const summaryResponse = await callOpenAPI(
          summaryPrompt,
          updatedMessageHistoryLocal, 
          customSystemMessage
        );

        // 5) Voeg die samenvatting als 'assistant' toe aan je conversatie
        updatedMessageHistoryLocal = updatedMessageHistoryLocal.concat({
          role: 'assistant',
          content: summaryResponse,
        });
        setActiveConversationMessages(updatedMessageHistoryLocal);

        // Tot hier ben je klaar met de Bing-flow. 
        // Als je verder niets meer met "message" wilt doen, kun je hieronder returnen.
        // Wil je wél nog de user-vraag naar OpenAI sturen (dus nog een extra call)? 
        // Dan kun je dat erna doen. 
        // Voor nu ga ik ervan uit dat de “samenvatting” je finale response is.
        setIsProcessing(false);
        setInputMessage('');
        inputRef.current?.focus();
        return;
      }

      // ------------------ Zo niet, dan gewoon normale AI-call ------------------
      const response = await callOpenAPI(
        message,
        updatedMessageHistoryLocal,
        customSystemMessage
      );

      // Tekstueel response verwerken
      const updatedMessage = response || 'Oops! Er ging iets mis.';

      // "simulate streaming"
      await simulateStreamingText(
        updatedMessage,
        updatedMessageHistoryLocal,
        setStreamedResponse,
        setActiveConversationMessages
      );
    } catch (error) {
      console.error('Error calling OpenAI or Bing:', error);
      const errorMessage = 'Oops! Er ging iets mis.';
      const errorHistory = updatedMessageHistoryLocal.concat({
        role: 'system',
        content: errorMessage,
      });
      setActiveConversationMessages(errorHistory);
    }

    setIsProcessing(false);
    setInputMessage('');
    inputRef.current?.focus();
  },
  [
    useWebSearch,
    getActiveConversationMessages,
    setActiveConversationMessages,
    customSystemMessage,
    selectedSearchTypes
  ]
);


  const handleKeyPress = useCallback(
    (e) => {
      if (e.key === 'Enter') {
        if (e.shiftKey) {
          return; // allow multiline
        }
        e.preventDefault();
        handleSendMessage(inputMessage);
      }
    },
    [inputMessage, handleSendMessage]
  );

  
  // ====== RENDER ====== //
  const renderedMessages = useMemo(() => {
    // Example color approach:
   

    const currentMsgs = getActiveConversationMessages();
    return (
      <TransitionGroup component={null}>
        {currentMsgs?.map((msg, index) => {
          const isUser = msg.role === 'user';
          const isEditing = editIndex === index;
          const cardStyle = isUser
          ? {
              margin: '16px 0',
              color: '#333',
            }
          : {
            margin: '16px 0',

              backgroundColor: '#F6F5F2',  // Light bluish for bot
              color: '#333',
            };
          // If we're currently editing this user-message
          if (isEditing && isUser) {
            return (
              <CSSTransition key={index} timeout={300} classNames="fade">
                <Card style={cardStyle}>
                  <Input.TextArea
                    rows={3}
                    value={editContent}
                    onChange={(e) => setEditContent(e.target.value)}
                    style={{ marginBottom: 8 }}
                  />
                  <Space>
                    <Button
                      icon={<SaveOutlined />}
                      type="primary"
                      onClick={handleSaveEdit}
                    >
                      Save
                    </Button>
                    <Button
                      icon={<CloseCircleOutlined />}
                      danger
                      onClick={handleCancelEdit}
                    >
                      Cancel
                    </Button>
                  </Space>
                </Card>
              </CSSTransition>
            );
          }

          // Normal messages
          return (
            <CSSTransition key={index} timeout={300} classNames="fade">
              <Card
                style={cardStyle}
              >
                <div style={{ whiteSpace: 'pre-wrap' }}>
                  <MarkdownRenderer content={msg.content} />
                </div>
                <div style={{ marginTop: '8px', textAlign: 'right' }}>
                  <Space>
                    {isUser && (
                      <Tooltip title="Edit">
                        <Button
                          icon={<EditOutlined />}
                          onClick={() => handleEditMessage(index)}
                          size="small"
                        />
                      </Tooltip>
                    )}
                    <Tooltip title="Copy">
                      <Button
                        icon={<CopyOutlined />}
                        onClick={() => handleCopyMessage(msg.content)}
                        size="small"
                      />
                    </Tooltip>
                    {!isUser && (
                      <Tooltip title="Retry">
                        <Button
                          icon={<RedoOutlined />}
                          onClick={() => handleRetryMessage(index - 1)}
                          size="small"
                        />
                      </Tooltip>
                    )}
                  </Space>
                </div>
              </Card>
            </CSSTransition>
          );
        })}
      </TransitionGroup>
    );
  }, [
    getActiveConversationMessages,
    editIndex,
    editContent,
    handleCancelEdit,
    handleCopyMessage,
    handleEditMessage,
    handleRetryMessage,
    handleSaveEdit,
  ]);

  return (
    <OscarContext.Provider value={{}}>
      <Layout style={{ minHeight: '100vh' }}>
        {/* ====== SIDER ====== */}
        <Sider
          width={240}
          theme="light"
          style={{ borderRight: '1px solid #f0f0f0' }}
          breakpoint="lg"
          collapsedWidth="0"
        >
          <Space
            align="center"
            style={{
              height: 64,
              paddingLeft: 16,
              borderBottom: '1px solid #f0f0f0',
              width: '100%',
            }}
          >
            {/* optional logo or heading */}
          </Space>
          <Menu
            mode="inline"
            selectedKeys={[`${selectedConversationId}`]}
            style={{ height: 'calc(95% - 60px)', borderRight: 0 }}
          >
            <Typography.Title level={4} style={{ margin: 0 }}>
              Conversations
            </Typography.Title>
            {conversations?.map((c) => (
              <Menu.Item key={c.id} onClick={() => handleSelectConversation(c.id)}>
                <Space
                  style={{ justifyContent: 'space-between', width: '100%' }}
                >
                  <span>{c.name}</span>
                  <Space>
                    <Tooltip title="Rename">
                      <Button
                        icon={<EditOutlined />}
                        size="small"
                        onClick={(e) => {
                          e.stopPropagation();
                          const newName = prompt('Enter new conversation name:', c.name);
                          if (newName) {
                            handleRenameConversation(c.id, newName);
                          }
                        }}
                      />
                    </Tooltip>
                    <Tooltip title="Delete">
                      <Button
                        icon={<DeleteOutlined />}
                        size="small"
                        danger
                        onClick={(e) => {
                          e.stopPropagation();
                          handleDeleteConversation(c.id);
                        }}
                      />
                    </Tooltip>
                  </Space>
                </Space>
              </Menu.Item>
            ))}
          </Menu>
          <div style={{ padding: 16 }}>
            <Button
              block
              icon={<PlusOutlined />}
              onClick={() => handleCreateNewConversation(firstName)}
            >
              New Conversation
            </Button>
          </div>
        </Sider>

        {/* ====== CONTENT ====== */}
        <Layout>
          <Content
            style={{
              padding: '16px',
              overflowY: 'auto',
              background: '#fafafa',
              maxHeight: '85vh',
            }}
            ref={chatHistoryRef}
          >
            <Row justify="center">
              <Col xs={24} sm={24} md={22} lg={20} xl={18}>
                <Space direction="vertical" style={{ width: '100%' }} size="large">
                  {/* RENDER MESSAGES */}
                  {renderedMessages}

                  {/* PARTIAL STREAMING */}
                  {isProcessing && streamedResponse ? (
                    <Card style={{ marginBottom: 8, alignSelf: 'flex-start' }}>
                      <div style={{ whiteSpace: 'pre-wrap' }}>
                        <MarkdownRenderer content={streamedResponse} />
                      </div>
                    </Card>
                  ) : null}

                  {isProcessing && !streamedResponse && (
                    <Card style={{ marginBottom: 8 }}>
                      <Skeleton active paragraph={{ rows: 1 }} />
                    </Card>
                  )}
                </Space>
              </Col>
            </Row>
          </Content>

          {/* ====== FOOTER ====== */}
          <Footer
            style={{
              backgroundColor: '#fff',
              borderTop: '1px solid #f0f0f0',
              padding: '16px',
            }}
          >
            <Row justify="center">
              <Col xs={24} sm={24} md={22} lg={20} xl={18}>
                <Space wrap style={{ marginBottom: 8, width: '100%' }}>
                  {questions?.map((question, index) => (
                    <Button
                      key={index}
                      onClick={() => handleSendMessage(question)}
                      disabled={isProcessing}
                      style={{
                        backgroundColor: secondaryColor,
                        borderColor: secondaryColor,
                        color: '#fff',
                      }}
                    >
                      {question}
                    </Button>
                  ))}
                </Space>

                <div style={{ display: 'flex', gap: '8px', alignItems: 'flex-start' }}>
                  <Popconfirm
                    title="Weet je zeker dat je een nieuwe chat wil starten?"
                    onConfirm={handleClearChat}
                    okText="Yes"
                    cancelText="No"
                  >
                    <Button
                      icon={<DeleteOutlined />}
                      disabled={isProcessing}
                      style={{
                        backgroundColor: '#ff4d4f',
                        borderColor: '#ff4d4f',
                        color: '#fff',
                      }}
                    />
                  </Popconfirm>

                  <Input.TextArea
                    value={inputMessage}
                    placeholder="Typ hier je vraag..."
                    onChange={(e) => setInputMessage(e.target.value)}
                    onPressEnter={handleKeyPress}
                    autoSize={{ minRows: 1, maxRows: 4 }}
                    disabled={isProcessing}
                    ref={inputRef}
                    style={{ borderRadius: '4px', resize: 'none' }}
                  />

                  <Button
                    type="primary"
                    icon={<SendOutlined />}
                    onClick={() => handleSendMessage(inputMessage)}
                    disabled={isProcessing}
                    style={{
                      backgroundColor: secondaryColor,
                      borderColor: secondaryColor,
                    }}
                  />

                  <Tooltip title="Custom System Message">
                    <Button
                      icon={<SettingOutlined />}
                      onClick={handleDialogOpen}
                      style={{
                        backgroundColor: mainColor,
                        borderColor: mainColor,
                        color: '#fff',
                      }}
                    />
                  </Tooltip>
                </div>

                {/* ====== NEW BING SEARCH OPTIONS ====== */}
                <div style={{ marginTop: 8, display: 'flex', gap: '16px' }}>
                  <Space>
                    <Switch
                      checked={useWebSearch}
                      onChange={() => setUseWebSearch(!useWebSearch)}
                    />
                    <span>Web Search</span>
                  </Space>

                  {/* If Web Search is enabled, show checkboxes */}
                  {useWebSearch && (
                    <Checkbox.Group
                      options={[
                        { label: 'Web', value: 'web' },
                        { label: 'News', value: 'news' },
                        { label: 'Images', value: 'images' },
                      ]}
                      value={selectedSearchTypes}
                      onChange={(vals) => setSelectedSearchTypes(vals)}
                    />
                  )}

                  {/* FILE UPLOAD (unchanged) */}
                  <Upload
                    name="file"
                    onChange={(info) => handleFileUploadChange(info, setUploadedFile, antdMessage)}
                    showUploadList={true}
                  >
                    <Button 
                      icon={<UploadOutlined />}
                      disabled={true}
                    >Klik om te uploaden</Button>
                  </Upload>
                  {uploadedFile && (
                    <div style={{ marginTop: 4 }}>
                      Geüpload bestand: {uploadedFile.file.name}
                    </div>
                  )}
                </div>
              </Col>
            </Row>
          </Footer>
        </Layout>
      </Layout>

      <Modal
        title="Custom System Message"
        visible={isDialogOpen}
        onCancel={handleDialogClose}
        footer={null}
        destroyOnClose
      >
        <Input.TextArea
          value={customSystemMessage}
          onChange={(e) => setCustomSystemMessage(e.target.value)}
          rows={5}
          placeholder="Voer je extra systeembericht in..."
        />
        <Button
          type="primary"
          onClick={handleDialogClose}
          style={{ marginTop: '16px', float: 'right' }}
        >
          Sluiten
        </Button>
      </Modal>

      {/* Some quick CSS for fade animation */}
      <style jsx>{`
        .fade-enter {
          opacity: 0.01;
        }
        .fade-enter.fade-enter-active {
          opacity: 1;
          transition: opacity 300ms ease-in;
        }
        .fade-exit {
          opacity: 1;
        }
        .fade-exit.fade-exit-active {
          opacity: 0.01;
          transition: opacity 300ms ease-in;
        }
      `}</style>
    </OscarContext.Provider>
  );
};

export default Oscar;