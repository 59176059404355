import React from 'react';
import { Button, message as antdMessage } from 'antd';
import { CopyOutlined } from '@ant-design/icons';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import ReactDOMServer from 'react-dom/server';

// Prism-based Syntax Highlighter
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { oneDark } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { Margin } from '@mui/icons-material';

/**
 * Helper to flatten a Markdown AST node into plain text.
 * This function recurses through all children to collect text.
 */
function flattenNode(node) {
    if (!node) return '';
    if (typeof node === 'string') return node; // raw string
    if (node.type === 'text') return node.value || '';
    if (!node.children) return '';

    return node.children.map(flattenNode).join('');
}

/**
 * Helper to copy text or HTML to the clipboard,
 * using fallback if ClipboardItem is not supported.
 */
const copyToClipboard = async ({ html, text }) => {
    try {
        if (navigator?.clipboard?.write && window.ClipboardItem) {
            await navigator.clipboard.write([
                new window.ClipboardItem({
                    'text/html': new Blob([html], { type: 'text/html' }),
                    'text/plain': new Blob([text], { type: 'text/plain' }),
                }),
            ]);
        } else {
            // Fallback: plain text only
            await navigator.clipboard.writeText(text);
        }
        antdMessage.success('Gekopieerd naar klembord!');
    } catch (error) {
        antdMessage.error('Kopiëren mislukt.');
    }
};

/**
 * A simple wrapper that:
 *  - Has a modern "elevated" look
 *  - Has a top-right copy button
 *  - Left-aligns content
 */
const CopyableWrapper = ({ onCopy, children }) => {
    const wrapperStyle = {
        position: 'relative',
        borderRadius: 4,
        backgroundColor: '#F6F5F2',
        border: '1px solid #ddd',
        textAlign: 'left',
        };

    const buttonStyle = {
        position: 'absolute',
        top: 0,
        right: 0,
    };

    return (
        <div style={wrapperStyle}>
            {children}
            <Button
                icon={<CopyOutlined />}
                size="small"
                style={buttonStyle}
                onClick={onCopy}
            />
        </div>
    );
};

/**
 * MarkdownRenderer
 *  - Uses "react-syntax-highlighter" for code syntax
 *  - Adds a copy button to code blocks, tables, lists,
 *    and now also headings, blockquotes, paragraphs, images, links, etc.
 *  - Extends basic ReactMarkdown with remarkGFM for tables/lists
 */
const MarkdownRenderer = ({ content }) => {
    const components = {
        /**
         * Code blocks & inline code
         */
        code({ node, inline, className, children, ...props }) {
            const codeText = String(children).replace(/\n$/, '');
            const match = /language-(\w+)/.exec(className || '');

            // Code block with syntax highlighting if a language is specified
            if (!inline && match) {
                return (
                    <CopyableWrapper
                        onCopy={() =>
                            copyToClipboard({
                                html: `<pre><code>${codeText}</code></pre>`,
                                text: codeText,
                            })
                        }
                    >
                        <SyntaxHighlighter
                            style={oneDark}
                            language={match[1]}
                            PreTag="div"
                            {...props}
                        >
                            {codeText}
                        </SyntaxHighlighter>
                    </CopyableWrapper>
                );
            }

            // Inline code or code block without a recognized language
            return (
                <CopyableWrapper
                    onCopy={() =>
                        copyToClipboard({
                            html: `<pre><code>${codeText}</code></pre>`,
                            text: codeText,
                        })
                    }
                >
                    <code {...props} style={{ background: '#f1f1f1', padding: '2px 4px' }}>
                        {children}
                    </code>
                </CopyableWrapper>
            );
        },

        /**
         * Tables (GFM)
         */
        table({ node, children, ...props }) {
            // 1) Generate the text version (tab-delimited)
            const tableText = node.children
                ?.map((row) => {
                    if (!row.children) return '';
                    return row.children
                        ?.map((cell) => flattenNode(cell))
                        .join('\t');
                })
                .join('\n');

            // 2) Generate the HTML version
            const tableHTMLString = ReactDOMServer.renderToStaticMarkup(
                <table {...props}>{children}</table>
            );

            return (
                <CopyableWrapper
                    onCopy={() => copyToClipboard({ html: tableHTMLString, text: tableText })}
                >
                    <table {...props}>{children}</table>
                </CopyableWrapper>
            );
        },

        /**
         * Lists (UL and OL)
         */
        ul({ node, children, ...props }) {
            const listText = node.children
                ?.map((li) => flattenNode(li))
                .join('\n');

            // We'll just reuse the text for both HTML & text
            // or you can create an HTML string with ReactDOMServer if desired
            const listHTML = ReactDOMServer.renderToStaticMarkup(
                <ul {...props}>{children}</ul>
            );

            return (
                <CopyableWrapper
                    onCopy={() => copyToClipboard({ html: listHTML, text: listText })}
                >
                    <ul {...props}>{children}</ul>
                </CopyableWrapper>
            );
        },

        ol({ node, children, ...props }) {
            const listText = node.children
                ?.map((li) => flattenNode(li))
                .join('\n');

            const listHTML = ReactDOMServer.renderToStaticMarkup(
                <ol {...props}>{children}</ol>
            );

            return (
                <CopyableWrapper
                    onCopy={() => copyToClipboard({ html: listHTML, text: listText })}
                >
                    <ol {...props}>{children}</ol>
                </CopyableWrapper>
            );
        },

        /**
         * Headings (H1-H6)
         *  We flatten the node's text for the plain text copy,
         *  and generate an HTML version for advanced copy.
         */
        heading({ node, level, children, ...props }) {
            const text = flattenNode(node);
            const headingHTML = ReactDOMServer.renderToStaticMarkup(
                React.createElement(`h${level}`, props, children)
            );

            return (
                <CopyableWrapper
                    onCopy={() => copyToClipboard({ html: headingHTML, text })}
                >
                    {React.createElement(`h${level}`, { ...props }, children)}
                </CopyableWrapper>
            );
        },

        /**
         * Blockquote
         */
        blockquote({ node, children, ...props }) {
            const text = flattenNode(node);
            const blockquoteHTML = ReactDOMServer.renderToStaticMarkup(
                <blockquote {...props}>{children}</blockquote>
            );

            return (
                <CopyableWrapper
                    onCopy={() => copyToClipboard({ html: blockquoteHTML, text })}
                >
                    <blockquote {...props}>{children}</blockquote>
                </CopyableWrapper>
            );
        },


        /**
         * Image (<img>)
         *  We'll copy the alt text + URL as plain text, and the full <img> as HTML.
         */
        img({ node, src, alt, ...props }) {
            const text = `![${alt}](${src})`; // standard Markdown image reference
            const imageHTML = ReactDOMServer.renderToStaticMarkup(
                <img src={src} alt={alt} {...props} />
            );

            return (
                <CopyableWrapper
                    onCopy={() => copyToClipboard({ html: imageHTML, text })}
                >
                    <img src={src} alt={alt} {...props} />
                </CopyableWrapper>
            );
        },

        /**
         * Link (<a>)
         *  We can copy the "display text" + link URL as plain text, or the full HTML.
         */
        a({ node, children, href, ...props }) {
            const linkText = `[${flattenNode(node)}](${href})`;
            const linkHTML = ReactDOMServer.renderToStaticMarkup(
                <a href={href} {...props}>
                    {children}
                </a>
            );

            return (
                    <a href={href} target="_blank" rel="noreferrer" {...props}>
                        {children}
                    </a>
            );
        },
    };

    return (
        <ReactMarkdown
            remarkPlugins={[remarkGfm]}
            // We override many elements with custom components:
            components={components}
        >
            {content}
        </ReactMarkdown>
    );
};

export default MarkdownRenderer;
