// OscarApi.js

/**
 * Simulates chunk-by-chunk streaming by slicing text.
 * In a real scenario, you'd handle actual streaming from your server or OpenAI.
 */
export const simulateStreamingText = async (
    text,
    updatedMessageHistory,
    setStreamedResponse,
    setActiveConversationMessages
  ) => {
    setStreamedResponse(''); // reset partial
  
    let tempText = '';
    const words = text.split(' ');
    for (let i = 0; i < words.length; i++) {
      // Simulate small chunk
      tempText += words[i] + ' ';
      setStreamedResponse(tempText);
      await new Promise((res) => setTimeout(res, 3)); // small delay
    }
  
    // Once streaming is done, push final message
    const finalHistory = updatedMessageHistory.concat({
      role: 'system',
      content: tempText.trim(),
    });
    setActiveConversationMessages(finalHistory);
    setStreamedResponse(''); // reset partial
  };
  
  /**
   * Mock function to handle an "OpenAI-like" request.
   * Replace with your actual fetch logic to call OpenAI or a custom AI.
   */
  export const callOpenAPI = async (message, updatedMessageHistory, customSystemMessage) => {
    const url = process.env.REACT_APP_AIMODEL_API_URL;
    const apiKey = process.env.REACT_APP_API_KEY_CHATBOT;


  
    const sysMsg = `
  Maak je antwoord in Markdown. Je bent een AI-assistent die mensen helpt bij het vinden van informatie.
  Je naam is OSCAR, het Open System Chatbot Assistant Rembrandt.
  Geef alleen de inhoud van het antwoord, niet teveel franje.
    `;
    const fullSysMsg = `${sysMsg}\n${customSystemMessage}`;
  
    const data = {
      messages: [
        {
          role: 'system',
          content: fullSysMsg,
        },
        ...updatedMessageHistory,
        { role: 'user', content: message },
      ],
      max_tokens: 3500,
      temperature: 0.7,
      frequency_penalty: 0,
      presence_penalty: 0,
      top_p: 0.95,
      stop: null,
    };
  
    try {
      const headers = {
        'Content-Type': 'application/json',
        'api-key': apiKey,
      };
      const response = await fetch(url, {
        method: 'POST',
        headers,
        body: JSON.stringify(data),
      });
      const json = await response.json();
      return json?.choices?.[0]?.message?.content || 'Oops! Er ging iets mis.';
    } catch (error) {
      console.error('OpenAI API error:', error);
      return 'Oops! Er ging iets mis.';
    }
  };
  
  /**
   * Handle file upload results
   */
  export const handleFileUploadChange = (info, setUploadedFile, antdMessage) => {
    if (info.file.status === 'done') {
      antdMessage.success(`${info.file.name} file uploaded successfully`);
      setUploadedFile(info.file);
    } else if (info.file.status === 'error') {
      antdMessage.error(`${info.file.name} file upload failed.`);
    }
  };
  

  /**
 * Calls Bing for different search types (web, news, images, etc.)
 * and returns a combined JSON object with the results.
 * 
 * @param {string} query - the user’s search query
 * @param {Array<string>} searchTypes - e.g. ["web", "news", "images"]
 * @returns {Object} combined Bing search results
 */
export const callBingSearch = async (query, searchTypes) => {
    const BING_SUBSCRIPTION_KEY = "826259a13bf9446aa3bb2e509232fc97";
    const BING_ENDPOINT = "https://api.bing.microsoft.com";
    const headers = { 'Ocp-Apim-Subscription-Key': BING_SUBSCRIPTION_KEY };
    const results = {};
  
    // For each searchType, call the relevant Bing endpoint
    for (const type of searchTypes) {
      try {
        let endpointUrl = '';
        // Decide which endpoint to call based on `type`
        switch (type) {
          case 'web':
            // For normal web search
            endpointUrl = `${BING_ENDPOINT}/v7.0/search?q=${encodeURIComponent(query)}&mkt=nl-NL`;
            break;
          case 'news':
            // For news
            endpointUrl = `${BING_ENDPOINT}/v7.0/news/search?q=${encodeURIComponent(query)}&mkt=nl-NL`;
            break;
          case 'images':
            // For images
            endpointUrl = `${BING_ENDPOINT}/v7.0/images/search?q=${encodeURIComponent(query)}&mkt=nl-NL`;
            break;
          default:
            endpointUrl = `${BING_ENDPOINT}/v7.0/search?q=${encodeURIComponent(query)}&mkt=nl-NL`;
            break;
        }
  
        const response = await fetch(endpointUrl, { headers });
        const jsonData = await response.json();
        results[type] = jsonData; // e.g. results["web"] = {...}, results["news"] = {...}
      } catch (error) {
        console.error(`Error calling Bing for type: ${type}`, error);
        results[type] = { error: true, message: error.toString() };
      }
    }
  
    return results;
  };