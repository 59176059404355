import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from './Home';
import Navbar from './Navbar';
import Footer from './Footer';
import CustomBreadcrumbs from './CustomBreadcrumbs';
import NotFoundPage from './NotFoundPage';
import Grid from '@mui/material/Grid';
import OscarChat from '../Components/OscarChat2';
import './AuthenticatedApp.css';

import { useAtom } from 'jotai';
import { accountUserNameAtom, softwareTeamAtom, uwwTeamAtom, allowedToEditAtom } from '../Atoms';

//Editors 

//ITHANDLEIDINGEN
import PowerPointEditor from '../Editors/ITHandleidingen/PowerpointEditor';
import WordEditor from '../Editors/ITHandleidingen/WordEditor';
import UrenApplicatieEditor from '../Editors/ITHandleidingen/UrenApplicatieEditor';
import ItAlgemeenEditor from '../Editors/ITHandleidingen/ItAlgemeenEditor';
import ExcelEditor from '../Editors/ITHandleidingen/ExcelEditor';
import OutlookWebEditor from '../Editors/ITHandleidingen/OutlookWebEditor';
import CrisOscarEditor from '../Editors/ITHandleidingen/CrisOscarEditor';
import ITUpdatesEditor from '../Editors/ITHandleidingen/ITUpdatesEditor';
import BetaTestingEditor from '../Editors/ITHandleidingen/BetaTestingEditor';
import CMSignEditor from '../Editors/ITHandleidingen/CMSignEditor';

//UNIFORME WERKWIJZE
import SharePointEditor from '../Editors/UniformeWerkwijze/SharePointEditor';
import DynamicsEditor from '../Editors/UniformeWerkwijze/DynamicsEditor';
import AdministratieEditor from '../Editors/UniformeWerkwijze/AdministratieEditor';
import ExcelEditorUWW from '../Editors/UniformeWerkwijze/ExcelEditorUWW';
import OutlookWebEditorUWW from '../Editors/UniformeWerkwijze/OutlookWebEditorUWW';
import PowerPointEditorUWW from '../Editors/UniformeWerkwijze/PowerpointEditorUWW';
import KYC from '../Editors/UniformeWerkwijze/KYC';
import OneNoteEditorUWW from '../Editors/UniformeWerkwijze/OneNoteEditorUWW';
import Marketing from '../Editors/UniformeWerkwijze/MarketingEditor';

//TRAININGEN
import PowerBIEditor from '../Editors/Trainingen/PowerBIEditor';
import TipsEnTricksEditor from '../Editors/Trainingen/TipsEnTricksEditor';

//IT UPDATES
import ExcelEditorIU from '../Editors/ITUpdates/ExcelEditorIU';
import OutlookWebEditorIU from '../Editors/ITUpdates/OutlookWebEditorIU';
import PowerPointEditorIU from '../Editors/ITUpdates/PowerpointEditorIU';
import WordEditorIU from '../Editors/ITUpdates/WordEditorIU';
import UrenApplicatieEditorIU from '../Editors/ITUpdates/UrenApplicatieEditorIU';
import ItAlgemeenEditorIU from '../Editors/ITUpdates/ItAlgemeenEditorIU';
import CrisOscarEditorIU from '../Editors/ITUpdates/CrisOscarEditorIU';

//Knowledge Articles
import DefinitiesEditor from '../Editors/KnowledgeArticles/DefinitiesEditor';
import ExperiencesEditor from '../Editors/KnowledgeArticles/ExperiencesEditor';
import FAQInternEditor from '../Editors/KnowledgeArticles/FAQInternEditor';
import IndustrieReportsEditor from '../Editors/KnowledgeArticles/IndustrieReportsEditor';
import OverigeEditor from '../Editors/KnowledgeArticles/OverigeEditor';

//HR Documenten
//  -Personeelsgids
import PersoneelsgidsEditor from '../Editors/HRDocumenten/Personeelsgids/PersoneelsgidsEditor';

// -Junior Consultant
import JuniorConsultantFinancieel from '../Editors/HRDocumenten/JuniorConsultant/JuniorConsultantFinancieel';
import JuniorConsultantFiscaal from '../Editors/HRDocumenten/JuniorConsultant/JuniorConsultantFiscaal';
import JuniorConsultantJuridisch from '../Editors/HRDocumenten/JuniorConsultant/JuniorConsultantJuridisch';
import JuniorConsultantOffice from '../Editors/HRDocumenten/JuniorConsultant/JuniorConsultantOffice';
import JuniorConsultantOverig from '../Editors/HRDocumenten/JuniorConsultant/JuniorConsultantOverig';

// -Consultant
import ConsultantFiscaal from '../Editors/HRDocumenten/Consultant/ConsultantFiscaal';
import ConsultantJuridisch from '../Editors/HRDocumenten/Consultant/ConsultantJuridisch';
import ConsultantOffice from '../Editors/HRDocumenten/Consultant/ConsultantOffice';

// -Senior Consultant / Manager
import SeniorConsultantFiscaal from '../Editors/HRDocumenten/SeniorConsultant/SeniorConsultantFiscaal';
import SeniorConsultantJuridisch from '../Editors/HRDocumenten/SeniorConsultant/SeniorConsultantJuridisch';

// -Online Kennissessies
import Kennissessie1 from '../Editors/HRDocumenten/OnlineKennissessies/Kennissessie1';
import Kennissessie2 from '../Editors/HRDocumenten/OnlineKennissessies/Kennissessie2';
import Kennissessie3 from '../Editors/HRDocumenten/OnlineKennissessies/Kennissessie3';
import Kennissessie4 from '../Editors/HRDocumenten/OnlineKennissessies/Kennissessie4';

import CRIS from '../Components/Chat';


const AuthenticatedApp = () => {
  const [accountUserName] = useAtom(accountUserNameAtom);
  const [softwareTeam] = useAtom(softwareTeamAtom);
  const [uwwTeam] = useAtom(uwwTeamAtom);
  const [, setAllowedToEdit] = useAtom(allowedToEditAtom);

  if ( softwareTeam.includes(accountUserName.toLowerCase()) || uwwTeam.includes(accountUserName.toLowerCase()) ) {
    setAllowedToEdit(true);
  }
  else{
    setAllowedToEdit(false);
  }

  return (
<BrowserRouter>
<div style={{ top: 0, zIndex: 1, background: '#000' }}>
        <Navbar />
      </div>
      <Grid container style={{ minHeight: '100vh'}}>
        <Grid container style={{ minHeight: '100vh', justifyContent: 'center' }}>
        <div className="responsive-div" style={{ position: 'absolute', top: '100px', left: '50px'}}>
          <CustomBreadcrumbs />
        </div>
            <Routes>
              <Route path="/Excel-Add-in" element={<ExcelEditor />} />
              <Route path="/it-algemeen" element={<ItAlgemeenEditor />} />
              <Route path="/dynamics" element={<DynamicsEditor />} />
              <Route path="/power-bi" element={<PowerBIEditor />} />
              <Route path="/sharepoint" element={<SharePointEditor />} />
              <Route path="/word-Add-in" element={<WordEditor />} />
              <Route path="/PowerPoint-Add-in" element={<PowerPointEditor />} />
              <Route path="/urenapplicatie" element={<UrenApplicatieEditor />} />
              <Route path="/cm-sign" element={<CMSignEditor />} />
              <Route path="/beta-testing" element={<BetaTestingEditor />} />
              <Route path="/Tips-En-Tricks" element={<TipsEnTricksEditor />} />
              <Route path="/cris-en-oscar" element={<CrisOscarEditor />} />
              <Route path="/administratie" element={<AdministratieEditor />} />
              <Route path="/it-updates" element={<ITUpdatesEditor />} />
              <Route path="/outlook-web-Add-in" element={<OutlookWebEditor />} />
              <Route path="/Excel-Uniforme-Werkwijze" element={<ExcelEditorUWW />} />
              <Route path="/Outlook-Web-Uniforme-Werkwijze" element={<OutlookWebEditorUWW />} />
              <Route path="/PowerPoint-Uniforme-Werkwijze" element={<PowerPointEditorUWW />} />
              <Route path="/OneNote-UWW" element={<OneNoteEditorUWW />} />
              <Route path="/marketing-Uniforme-Werkwijze" element={<Marketing />} />
              <Route path="/Excel-IT-Updates" element={<ExcelEditorIU />} />
              <Route path="/Outlook-Web-IT-Updates" element={<OutlookWebEditorIU />} />
              <Route path="/PowerPoint-IT-Updates" element={<PowerPointEditorIU />} />
              <Route path="/Word-IT-Updates" element={<WordEditorIU />} />
              <Route path="/Uren-Applicatie-IT-Updates" element={<UrenApplicatieEditorIU />} />
              <Route path="/IT-Algemeen-IT-Updates" element={<ItAlgemeenEditorIU />} />
              <Route path="/Cris-Oscar-IT-Updates" element={<CrisOscarEditorIU />} />

              {/*Knowledge Articles*/}
              <Route path="/Definities" element={<DefinitiesEditor />} />
              <Route path="/Experiences" element={<ExperiencesEditor />} />
              <Route path="/FAQ-Intern" element={<FAQInternEditor />} />
              <Route path="/Industrie-Reports" element={<IndustrieReportsEditor />} />

              {/*HR Documenten*/}
              <Route path="/personeelsgids" element={<PersoneelsgidsEditor />} />
              <Route path="/Junior-Consultant-Financieel" element={<JuniorConsultantFinancieel />} />
              <Route path="/Junior-Consultant-Fiscaal" element={<JuniorConsultantFiscaal />} />
              <Route path="/Junior-Consultant-Juridisch" element={<JuniorConsultantJuridisch />} />
              <Route path="/Junior-Consultant-Office" element={<JuniorConsultantOffice />} />
              <Route path="/Junior-Consultant-Overig" element={<JuniorConsultantOverig />} />
              <Route path="/Consultant-Fiscaal" element={<ConsultantFiscaal />} />
              <Route path="/Consultant-Juridisch" element={<ConsultantJuridisch />} />
              <Route path="/Consultant-Office" element={<ConsultantOffice />} />
              <Route path="/Senior-Consultant-Fiscaal" element={<SeniorConsultantFiscaal />} />
              <Route path="/Senior-Consultant-Juridisch" element={<SeniorConsultantJuridisch />} />
              <Route path="/Kennissessie-1" element={<Kennissessie1 />} />
              <Route path="/Kennissessie-2" element={<Kennissessie2 />} />
              <Route path="/Kennissessie-3" element={<Kennissessie3 />} />
              <Route path="/Kennissessie-4" element={<Kennissessie4 />} />

              <Route path="/Overige" element={<OverigeEditor />} />
              <Route path="/KYC" element={<KYC />} />
              <Route path="/" element={<Home />} />
              <Route path="/Oscar" element={<OscarChat />} />
              <Route path="*" element={<NotFoundPage />} />
            </Routes>
        </Grid>
      </Grid>
      {/*<CRIS/>*/}
      <Grid item xs={12}>
        <Footer style={{ position: 'absolute', bottom: 0, left: 0, width: '100%'}} />
      </Grid>
    </BrowserRouter>
  );
};

export default AuthenticatedApp;
