// OscarContext.js

import React, { createContext, useContext } from 'react';

export const OscarContext = createContext(null);

export const useOscarContext = () => {
  const context = useContext(OscarContext);
  if (!context) {
    throw new Error('useOscarContext must be used within an OscarProvider');
  }
  return context;
};
